import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import AccountRepository from '@/shared/http/repositories/access/account'
import AccountCriteria from '@/shared/models/criteria/accountcriteria'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import AccountModel from '@/shared/models/account.js'
import Roles from '@/shared/mixins/hasRoles'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import { nameFormatter } from '@/shared/filters/ToNameFormatted'
import fab from 'vue-fab'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    fab
  },

  mixins: [Roles],

  data: () => ({
    criteria: new AccountCriteria(),
    accounts: [],
    teacherGroup: '6b1583ea-38ae-42f4-a881-f05d6ff7c0f1',
    onInactiveTeacher: false,
    teacherID: null,
    fields: [
      {
        key: 'name',
        label: 'NOME',
        class: 'left-header-border text-center',
        formatter: nameFormatter
      },
      {
        key: 'login',
        label: 'LOGIN',
        class: 'middle-header text-center'
      },
      {
        key: 'email',
        label: 'E-MAIL',
        class: 'middle-header text-center'
      },
      {
        key: 'groupName',
        label: 'GRUPO',
        class: 'middle-header text-center'
      },
      {
        key: 'statusText',
        label: 'SITUAÇÃO',
        class: 'middle-header text-center'
      },
      {
        key: 'cellPhone',
        label: 'CELULAR',
        class: 'middle-header text-center'
      },
      {
        key: 'phone',
        label: 'TELEFONE',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 15,
      index: 1
    },
    emptyText: 'Efetue uma busca para preencher a tabela',
    showModalDeleteAccount: false,
  }),

  created() {
    this.getAll()
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: "Dashboard",
            href: this.$router.resolve({ name: "Dashboard" }).href
          },
          {
            text: "Listagem de Contas",
            active: true
          }
        ]
      }
    }
  },

  methods: {
    getAll() {
      loading.push()
      AccountRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

           this.accounts = res.data.data.map(account => {
            return new AccountModel(account)
          })

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar contas', 'ERRO')
        })
    },

    selectRow(item) {
      this.showModalDeleteAccount = true
      this.id = item.id
      if (item.groupID === this.teacherGroup) {
        this.onInactiveTeacher = true
        return
      }
      this.onInactiveTeacher = false
    },

    onDelete() {
      loading.push()
      AccountRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()

          if (this.onInactiveTeacher) {
            this.getTeacherByAccount()

            setTimeout(() => {
              TeacherRepository.Delete(this.teacherID)
            }, 2000)
          }

          toast.success('Conta inativada com sucesso!', 'EXCLUSÃO')
          this.showModalDeleteAccount = false
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao inativar conta', 'ERRO')
        })
    },

    getTeacherByAccount() {
      loading.push()
      TeacherRepository.GetTeacherByAccountID(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (res.data.data) {
            this.teacherID = res.data.data.id
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professor pelo id da conta', 'ERRO')
        })
    },

    backTo() { this.$router.go(-1) }
  }
}
