export default class AccountCriteria {
  constructor({
    name,
    login,
    email,
  } = {}) {
    this.name = name
    this.login = login
    this.email = email
  }
}